/* .nav-logo-padding {
  padding-right: 50px;
} */
.nav-li-padding {
  padding: 10px 20px;
  font-size: 20px;
}
.nav-dropdown-li {
  padding: 10px 10px;
  font-size: 20px;
}
.modal-backdrop {
  z-index: 1040 !important;
}
.modal-content {
  margin: 2px auto;
  z-index: 1100 !important;
}

.logo-image {
  width: 100px;
}

@media screen and (min-width: 1200px) {
  .nav-ul-padding {
    justify-content: center;
    text-align: center;
    align-content: center;
    padding: 0 50px;
  }
  .brand-logo {
    margin-left: 140px;
    margin-right: 0px;
  }
}
@media screen and (min-width: 1400px) {
  .nav-ul-padding {
    justify-content: center;
    text-align: center;
    align-content: center;
    padding: 0 50px;
  }
  .brand-logo {
    margin-left: 180px;
    margin-right: 0px;
  }
}
@media screen and (min-width: 1500px) {
  .nav-ul-padding {
    justify-content: center;
    text-align: center;
    align-content: center;
    padding: 0 50px;
  }
  .brand-logo {
    margin-left: 240px;
    margin-right: 0px;
  }
}
@media screen and (min-width: 1600px) {
  .nav-ul-padding {
    justify-content: center;
    text-align: center;
    align-content: center;
    padding: 0 50px;
  }
  .brand-logo {
    margin-left: 290px;
    margin-right: 0px;
  }
}
@media screen and (min-width: 1700px) {
  .nav-ul-padding {
    justify-content: center;
    text-align: center;
    align-content: center;
    padding: 0 50px;
  }
  .brand-logo {
    margin-left: 340px;
    margin-right: 0px;
  }
}
@media screen and (min-width: 1800px) {
  .nav-ul-padding {
    justify-content: center;
    text-align: center;
    align-content: center;
    padding: 0 50px;
  }
  .brand-logo {
    margin-left: 390px;
    margin-right: 0px;
  }
}
@media screen and (min-width: 1900px) {
  .nav-ul-padding {
    justify-content: center;
    text-align: center;
    align-content: center;
    padding: 0 50px;
  }
  .brand-logo {
    margin-left: 440px;
    margin-right: 0px;
  }
}
@media screen and (min-width: 0px) and (max-width: 1000px) {
  .nav-dropdown-menu {
    text-align: center;
  }
  .nav-main-menu {
    text-align: center;
  }
  .brand-logo {
    margin-left: 30px;
    margin-right: 0px;
  }
  .nav-toogle-button {
    margin-right: 30px;
  }
}
