.solutions-slider {
  padding-top: 100px;
}

input {
  display: none;
}

label {
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 4px;
  background: #000000;
  color: rgb(255, 255, 255);
  font-family: arial;
  -webkit-transition: background-color 0.1s, color 0.1s;
}

label:hover {
  background: #272a26;
  color: rgb(57, 55, 55);
}

.test {
  -webkit-transition: height .3s ease;
  height: 0;
  overflow: hidden;
  background: rgb(214, 210, 210);
  margin-top: 10px;
}

input:checked + .test {
  height: 100px;
}

.center{
  text-align: center;
}

.groove {border-style: groove;

}

.boxset {
  justify-content: space-between;
	flex-wrap: wrap;
  display: flex;
  padding: 20px 0;
}


.paddingColor {
  background-color: white !important;
}
